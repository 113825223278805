$(document).ready( function () {
    $('.dataTable').DataTable({
        paging: false,
        responsive: {
            details: {
                display: $.fn.dataTable.Responsive.display.childRowImmediate,
                type: ''
            }
        }
    });
} );

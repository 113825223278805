import Chart from "chart.js";

$(document).ready( function () {

    $('[data-toggle="tooltip"]').tooltip();

    if( !$('#campaign-drl').length ) return;

    let chart = document.getElementById('campaign-drl');
    let ctx = chart.getContext('2d');
    let dataValues = JSON.parse( chart.dataset.values );
    let dates = dataValues.map( el => el.date );
    let values = dataValues.map( el => el.drl );

    let myLineChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: dates,
            datasets: [{
                pointBackgroundColor: 'rgb(96, 125, 139)',
                pointBorderColor: 'rgb(255, 255, 255)',
                borderColor: 'rgba(82, 183, 96, 0.8)',
                fill: false,
                data: values,
                lineTension: 0.1
            }]
        },
        options: {
            responsive: true,
            legend: {
                display: false
            }
        }
    });

    function beforePrintHandler () {
        for (var id in Chart.instances) {
            Chart.instances[id].resize();
        }
    }

    window.addEventListener( "beforeprint", 'beforePrintHandler' );

});

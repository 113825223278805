<template>
    <tr>
        <td class="campaign">
            <a :href="'/campaigns/' + campaign.id">{{ campaign.name }}</a>
        </td>
        <td class="drl">{{ campaign.drl }}</td>
        <td class="status"><button class="btn btn-default btn-xs" @click="processCampaignAssets" :class="status">{{ status }} <i class="zmdi zmdi-refresh"></i></button></td>
        <td class="start">{{ campaign.start }}</td>
        <td class="last-update">{{ campaign.last_update }}</td>
        <td class="queue" v-if="queue > 0"><i class="alerts"><a :href="'/campaigns/' + campaign.id + '/assets?filter=unmoderated'">{{ queue }}</a></i></td>
        <td class="queue" v-else>-</td>
        <td class="more">
            <ul class="actions">
                <li class="dropdown action-show">
                    <a href="" data-toggle="dropdown">
                        <i class="zmdi zmdi-more-vert"></i>
                    </a>

                    <ul class="dropdown-menu dm-icon pull-right">
                        <li>

                        </li>
                        <li>

                        </li>
                    </ul>
                </li>
            </ul>
        </td>
    </tr>
</template>

<script>
    export default {
        props: {
            campaign: {
                type: Object
            }
        },
        data() {
            return {
                status: this.campaign.status,
                queue: this.campaign.queue,
                processing: false
            }
        },
        created() {
            Echo.channel('campaign.status')
                .listen('CampaignStatus', (e) => {
                    if( e ) {
                        // console.log(e);
                        if( this.campaign.id == e.campaign_id ) {
                            this.status = e.status;
                            this.queue = e.campaign.queue;
                        }
                        if( e.keyword !== null ) {
                            this.processing = false;
                        }
                    }
                });
        },
        methods: {
            processCampaignAssets( event ) {
                event.preventDefault();
                if( ! this.processing ) {
                    this.processing = true;
                    axios.put( '/api/search_results/' + this.campaign.id + '/process' )
                    .then( (response) => {})
                    .catch( (error) => {
                        console.log(error.response);
                        this.processing = false;
                        this.error_message = "Asset processing failed w/ error: " + error.response.data.message;
                    });
                }
            }
        }
    }
</script>

<template>
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn favorable">
            <input type="radio" value="favorable" v-model="currently" @click="updateFavorability"> <i class='zmdi zmdi-thumb-up'></i>
        </label>
        <label class="btn neutral">
            <input type="radio" value="neutral" v-model="currently" @click="updateFavorability"> <i class='zmdi zmdi-view-stream'></i>
        </label>
        <label class="btn unfavorable">
            <input type="radio" value="unfavorable" v-model="currently" @click="updateFavorability"> <i class='zmdi zmdi-thumb-down'></i>
        </label>
    </div>
</template>

<script>
    export default {
        props: [
            'search_result',
        ],
        data() {
            return {
                currently: this.search_result.favorability
            }
        },
        methods: {
            updateFavorability( event ) {
                axios.put( '/api/search_results/' + this.search_result.id, {
                    favorability: event.target.value,
                    link: this.search_result.link,
                    campaign: this.search_result.campaign_id
                })
                .then( (response) => {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }
    }
</script>

<template>
    <div>
        <button type="button" class="btn btn-secondary" @click="processAssets">Process Assets</button>
       <div class="spinner" v-if="processing">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
        <div v-if="error_message">
            <p>{{ error_message }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            campaign: {
                type: Object
            }
        },
        data() {
            return {
                processing: false,
                error_message: false
            }
        },
        created() {
            Echo.channel('campaign.status')
                .listen('CampaignStatus', (e) => {
                    if( e ) {
                        // console.log(e);
                        if( e.status === 'active' ) {
                            this.processing = false;
                            // location.reload();
                            window.location.search += '?filter=unmoderated';
                        }
                    }
                });
        },
        methods: {
            processAssets( event ) {
                event.preventDefault();
                if( ! this.processing ) {
                    this.processing = true;
                    axios.put( '/api/search_results/' + this.campaign.id + '/process' )
                    .then( (response) => {
                        // this.processing = false;
                        // location.reload();
                    })
                    .catch( (error) => {
                        console.log(error.response);
                        this.processing = false;
                        this.error_message = "Asset processing failed w/ error: " + error.response.data.message;
                    });
                }
                // this.processing = true;
                // axios.put( '/api/search_results/' + this.campaign.id + '/process' )
                // .then( (response) => {
                //     this.processing = false;
                //     location.reload();
                // }, (error) => {
                //     console.log(error.response);
                //     this.processing = false;
                //     this.error_message = "Asset processing failed w/ error: " + error.response.data.message;
                // });
            }
        }
    }
</script>

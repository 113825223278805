$(document).ready( function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('*[data-method="delete"]').on( 'click', function( e ) {

        e.preventDefault();

        if (confirm('Are you sure you want to delete this?')) {
            $.ajax({
                url: e.target.href,
                type: 'DELETE',
                success: function(data) {
                    $(e.target).parents('tr').remove();
                    console.log(data);
                }
            });
            return false;
        }

    });

    $('select').selectpicker();

    /*----------------------------------------------------------
        Text Field
    -----------------------------------------------------------*/
    //Add blue animated border and remove with condition when focus and blur
    if($('.fg-line')[0]) {
        $('body').on('focus', '.fg-line .form-control', function(){
            $(this).closest('.fg-line').addClass('fg-toggled');
        })

        $('body').on('blur', '.form-control', function(){
            var p = $(this).closest('.form-group, .input-group');
            var i = p.find('.form-control').val();

            if (p.hasClass('fg-float')) {
                if (i.length == 0) {
                    $(this).closest('.fg-line').removeClass('fg-toggled');
                }
            }
            else {
                $(this).closest('.fg-line').removeClass('fg-toggled');
            }
        });
    }


    // $('.nested-fields .zmdi-close').click(function() {
    //     $(this).parent().next('input').val(true)
    //     $(this).parent().remove()
    // });


    /* adding Keywords to Campaign */
    // $('.campaigns-edit .add_fields, .campaigns-create .add_fields').trigger('click').css('display','none');
    $('.campaigns').on( 'click', '.zmdi-close', function() {
      $(this).parent().remove();
    })

    $('input[name="keywordsInput"]').on( 'keydown', function(e) {
        if( e.which == 13 || e.keyCode == 13 ) {
            e.preventDefault();

            var $input = $(this),
                $phrase = $(this).val(),
                $parent = $(this).parent(),
                $new_el = $('<li class="nested-fields">' + $phrase + '</li>'),
                $x_button = $('<i class="zmdi zmdi-close"></i>'),
                $existingValues = $('input[name="keywords[]"]').val()


            try {

                var $hiddenInput = $('<input>').attr({
                    type: 'hidden',
                    name: 'keywords[]',
                    value: $phrase
                });

                $new_el.append($x_button)
                $new_el.append($hiddenInput)
                $new_el.appendTo($('ul.keywords-tag'))
                $input.val('');

            } catch (e) {
                console.log(e);
            }

            return false;
        }
    });

});

<template>
    <div>
        <button class="btn btn-default btn-xs" @click="processCampaignAssets" :class="status">{{ status }} <i class="zmdi zmdi-refresh"></i></button>
    </div>
</template>

<script>
    export default {
        props: ['campaign'],
        data() {
            return {
                status: this.campaign.status,
                processing: false
            }
        },
        created() {
            Echo.private(`campaign-status`).listen('.crawler-status', (e) => {
            // Echo.private(`campaign-status.${this.campaign.id}`).listen('.crawler-status', (e) => {
                    // console.log(e);
                    if( e ) {
                        if( this.campaign.id === e.campaign_id ) {
                            this.status = e.status;
                        }
                    }
                });
        },
        methods: {
            processCampaignAssets( event ) {
                event.preventDefault();
                if( ! this.processing ) {
                    this.processing = true;
                    axios.put( '/api/search_results/' + this.campaign.id + '/process' )
                    .then( (response) => {})
                    .catch( (error) => {
                        console.log(error.response);
                        this.processing = false;
                        this.error_message = "Asset processing failed w/ error: " + error.response.data.message;
                    });
                }
            }
        }
    }
</script>

<template>
    <div>
        <p><button class="btn btn-default btn-xs" @click="processAssets" v-if="!processing">Process All Campaigns</button></p>
        <div class="alert alert-light" role="alert" v-if="processing">
            Processing <strong> {{ name }}</strong>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name: '',
                processing: false
            }
        },
        created() {
            try {
                axios.get('/campaigns')
                .then( (response) => {
                    this.campaigns = response.data
                })
            } catch (e) {
                // handle the authentication error here
            }
            Echo.private(`campaign-status`).listen('.crawler-status', (e) => {
                if( e ) {
                    // console.log(e);
                    this.processing = true;
                    this.name = e.campaign_name;
                    if( e.status === 'crawling' ) {
                        this.processing = true;
                    } else {
                        this.processing = false;
                    }
                }
            });
        },
        methods: {
            processAssets( event ) {
                event.preventDefault();
                if( ! this.processing ) {
                    this.processing = true;
                    axios.put( '/api/search_results/process-all' )
                    .then( (response) => {})
                    .catch( (error) => {
                        console.log(error.response);
                        this.processing = false;
                        this.error_message = "Asset processing failed w/ error: " + error.response.data.message;
                    });
                }
            }
        }
    }
</script>

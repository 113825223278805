<template>
    <i class="alerts" v-if="queue > 0">
        <a :href="'/campaigns/' + campaign.id + '/assets?filter=unmoderated'" v-if="queue > 0">{{ queue }}</a>
    </i>
    <span v-else>-</span>
</template>

<script>
    export default {
        props: ['campaign'],
        data() {
            return {
                queue: this.campaign.queue,
            }
        },
        created() {
            Echo.private(`campaign-status`).listen('.crawler-status', (e) => {
                    if( e ) {
                        console.log(e);
                        if( this.campaign.id === e.campaign_id ) {
                            this.queue = e.queue;
                        }
                    }
                });
        }
    }
</script>
